
import { defineComponent } from 'vue';

import MailingListsSelect from '@/components/forms/components/MailingListsSelect.vue';
import Navigator from '@/views/new-design/layout/Navigator.vue';

import { Actions, Getters, Mutations } from '@/store/enums/TemplateEnums';
import store from '@/store';
import { mapActions, mapGetters } from 'vuex';
import { Template } from '@/models/TemplateModel';

export default defineComponent({
  name: 'template-settings-page',
  components: {
    MailingListsSelect,
    Navigator,
  },
  data: () => ({
    options: [] as unknown as [],
    mailTo: '',
    currentTemplateType: '',
    loading: false,
    showForm: false,
  }),
  mounted() {
    this.initializeForm();
  },
  computed: {
    ...mapGetters({
      errors: Getters.GET_TEMPLATE_ERROR,
      mailingList: Getters.GET_MAILING_LIST,
      placeholders: Getters.GET_PLACEHOLDERS,
    }),
  },
  methods: {
    ...mapActions({
      fetchPlaceholders: Actions.FETCH_PLACEHOLDERS,
    }),

    initializeForm() {
      this.showForm = false;
      const templateType = this.$route.meta.template;
      const { template } = this.$route.query;
      this.currentTemplateType = templateType as string;

      if (typeof template !== 'undefined') {
        this.mailTo = template as string;
      }

      store.commit(Mutations.SET_TEMPLATE, {});
      store.commit(Mutations.SET_TEMPLATES, {});
    },

    handleMailListChange(value) {
      this.loading = true;

      this.mailTo = value;

      store.commit(Mutations.SET_TEMPLATE, {});
      store.commit(Mutations.SET_TEMPLATES, {});

      if (typeof this.mailTo !== 'undefined') {
        this.fetchPlaceholders(this.mailTo);
      }

      this.$router.push({
        path: '',
        query: { template: this.mailTo },
      });

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },

    setTitle() {
      const filteredList = this.mailingList.filter(
        (mailList: any | Template, key) => {
          return mailList?.system_name === this.mailTo;
        }
      );

      store.commit(Mutations.SET_TITLE, filteredList[0]?.name);
    },

    isActive(path) {
      return this.$route.path.includes(path);
    },
  },
  watch: {
    $route: {
      handler(value) {
        if (typeof value.query.template === 'undefined') {
          this.mailTo = '';
          this.showForm = false;
        }
      },
      immediate: true,
    },
  },
});
